unit DashboardItemSummaryTop5;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.ExtCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  SharedDataModule,
  Simple.Dashboard.Return.Types,
  BaseDashboardItem,
  DashboardItemSingleSummary,
  Data.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCChart, Vcl.Forms;


type
  TDashboardItemSummaryTop5 = class(TDashboardItemSingleSummary)
  private
  protected
    function GetChartHeight: integer; override;
    function GetSummaryRowCount: integer; override;
    function GetSummaryColCount: integer; override;

  public
  end;


implementation

{ TDashboardItemSummaryTop5 }

function TDashboardItemSummaryTop5.GetChartHeight: integer;
begin
  result := 388;
end;

function TDashboardItemSummaryTop5.GetSummaryColCount: integer;
begin
//  result := Length(Configuration.ColumnHeadings);
  result := SummaryFieldCount;
end;

function TDashboardItemSummaryTop5.GetSummaryRowCount: integer;
begin
  result := 5;
end;

end.
