unit Configuration;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, XData.Web.Client, MainDataModule,
  System.Generics.Collections, SMX.XData.Utils, Sys.Return.Types, Vcl.Controls,
  Vcl.StdCtrls, WEBLib.CheckLst, WEBLib.StdCtrls, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCTreeViewBase, VCL.TMSFNCTreeViewData,
  VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView, WEBLib.ExtCtrls,
  WEBLib.TreeNodes, Vcl.ComCtrls, WEBLib.ComCtrls;

type
  TBranchClass = class
    private
      FName: string;
      FId: string;
    public
      property Name: string read FName write FName;
      property Id: string read FId write FId;
  end;



  TConfigurationForm = class(TCoreWebForm)
    CloseButton: TButton;
    SaveButton: TButton;
    WebPanel1: TPanel;
    RegionTV: TTreeView;
    BranchTV: TTreeView;
    BranchListTimer: TTimer;
    procedure WebFormShow(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
    procedure RegionTVClickCheckBox(Sender: TObject; ANode: TTreeNode;
      AState: Boolean);
    procedure BranchListTimerTimer(Sender: TObject);
    //procedure CloseButtonClick(Sender: TObject);
  private
    { Private declarations }
    FGroupList: TStringList;
    FBranchList: TStringList;
    FBranchListForQuery: TStringList;

    FBranchListForSelection: TStringList;
    FBranchListIdsForSelection: TStringList;

    //procedure GetBranches;
    procedure SetBranchStructure(ABranchStructure: string);
    procedure AddBranches;
    procedure PopulateBranchNodes;

  public
    { Public declarations }

    procedure InitialiseConfig;

    property BranchStructure: string write SetBranchStructure;

    procedure RefreshRegionsBranches;
    procedure UpdateBranchIdsList;

    procedure RefreshBranchList;
  protected procedure LoadDFMValues; override; end;

var
  ConfigurationForm: TConfigurationForm;

implementation

{$R *.dfm}

{ TConfigurationForm }

procedure TConfigurationForm.WebFormShow(Sender: TObject);
begin
  inherited;
  InitialiseConfig
end;

procedure TConfigurationForm.AddBranches;
begin
end;


procedure TConfigurationForm.BranchListTimerTimer(Sender: TObject);
begin
//  inherited;
  BranchListTimer.Enabled := false;
  RefreshBranchList;
end;

procedure TConfigurationForm.CloseButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrClose;
end;

procedure TConfigurationForm.SaveButtonClick(Sender: TObject);
begin
//  inherited;
//  RefreshBranchList;
  ModalResult := mrOk;
end;

procedure TConfigurationForm.SetBranchStructure(ABranchStructure: string);
var
  lBranchStructure: string;
  BranchNode, SN, AreaNode: TTreeNode;
  lStream: TStringStream;

  lBranchStructureObj: JS.TJSObject;
  lIdx: integer;
  lIdx2: integer;
  lCount: integer;

  lBranchStructureArray: JS.TJSArray;
  lBranchObj: JS.TJSObject;

  lRegionArray: JS.TJSArray;
  lRegionName: string;
  lRegionObj: JS.TJSObject;
  lBranchesObj: JS.TJSArray;
  //lBranchesObj: JS.TJSValue;
  lBranchArray: JS.TJSArray;

  lBranchName: string;
  lBranchId: string;
  lSubBranch: TStringList;
  lBranchPos: integer;
  lBranchClass: TBranchClass;
begin
  FGroupList := TStringList.Create;
  FBranchList := TStringList.Create;

  lBranchStructure := ABranchStructure;// Await(SharedData.Dashboard.GetBranchStructure());
  lRegionArray := JS.toArray(TJSJSON.parse(lBranchStructure));

  RegionTV.BeginUpdate;

  for lIdx := 0 to lRegionArray.Length-1 do
  begin
    lSubBranch := TStringList.Create;
    lSubBranch.Sorted := true;
    lRegionObj := JS.toObject(lRegionArray[lIdx]);
    lBranchArray := JS.toArray(lRegionObj);

    lRegionName := String(lRegionObj.toString);
    lRegionName := Copy(lRegionName, 1, Pos(',', lRegionName)-1);
    BranchNode := RegionTV.Items.Add(lRegionName);

    FGroupList.AddObject(lRegionName, BranchNode);
    FBranchList.AddObject(lRegionName, lSubBranch);

    BranchNode.NodeType := ntCheckBox;
    //BranchNode.Selected := True;
    //BranchNode.Checked := True;


    for lIdx2 := 1 to lBranchArray.Length-1 do
    begin
      lBranchObj := JS.toObject(lBranchArray.Elements[lIdx2]);

      lBranchName := String(lBranchObj['BranchName']);
      lBranchId   := String(lBranchObj['BranchId']);

      lBranchClass := TBranchClass.Create;
      lBranchClass.Name := lBranchName;
      lBranchClass.Id := lBranchId;

      //SN := BranchTreeView.Items.AddChild(BranchNode, lBranchName);
      //SN.NodeType := ntCheckBox;
      //SN.Selected := True;
      //SN.Checked := False;// True;
      //lSubBranch.AddObject(lBranchId, SN);
      //lBranchPos := lSubBranch.Add(lBranchId);
      //lSubBranch.Add(lBranchName);
      lSubBranch.AddObject(lBranchName, lBranchClass);
    end;
  end;

  RegionTV.EndUpdate;
end;


procedure TConfigurationForm.RefreshRegionsBranches;
begin

end;

procedure TConfigurationForm.RegionTVClickCheckBox(Sender: TObject;
  ANode: TTreeNode; AState: Boolean);
begin
//  inherited;
//  RefreshBranchList;
  BranchListTimer.Enabled := true;
end;

procedure TConfigurationForm.UpdateBranchIdsList;
var
  lGroupIdx: integer;
  lBranchIdx: integer;
  lGroupNode: TTreeNode;
  lBranchNode: TTreeNode;
  lChecked: boolean;
  lBranchList: TStringList;
begin
//  BranchTreeView.BeginUpdate;
  FBranchListForQuery.Clear;
  for lGroupIdx := 0 to FGroupList.Count-1 do
  begin
    lBranchList := TStringList(FBranchList.Objects[lGroupIdx]);
    lGroupNode := TTreeNode(FGroupList.Objects[lGroupIdx]);
    //lChecked := lGroupNode.Checked;

    for lBranchIdx := 0 to lBranchList.Count-1 do
    begin
      lBranchNode := TTreeNode(lBranchList.Objects[lBranchIdx]);
      //lBranchNode.Checked := lChecked;

      if lBranchNode.Checked then
      begin
        FBranchListForQuery.Add(lBranchList[lBranchIdx]);
      end;
    end;
  end;
//  BranchTreeView.EndUpdate;
end;


procedure TConfigurationForm.RefreshBranchList;
var
  lRegionCount: integer;
  lRegionIdx: integer;
  lBranchCount: integer;
  lBranchIdx: integer;
  BranchNode, RegionNode: TTreeNode;
  lName: string;
  lChecked: boolean;
  lRegionIndex: integer;
  lBranchList: TStringList;
  lRegionName: string;
  lBranchName: string;
  lBranchNode: TTreeNode;
  lBranchPos: integer;
  //lBranchObj: JS.TJSObject;
  lBranchId: string;
  lBranchClass: TBranchClass;
begin
  FBranchListForSelection.Clear;
  lRegionCount := RegionTV.Items.Count;

  for lRegionIdx := 0 to lRegionCount-1 do
  begin
    RegionNode := RegionTV.Items[lRegionIdx];

    //lName := RegionNode.Text;
    lChecked := RegionNode.Checked;
    lRegionName := RegionNode.Text;


    if lChecked then
    begin
      lRegionIndex := FBranchList.IndexOf(lRegionName);
      //AddObject(lRegionName, lSubBranch);

      lBranchList := TStringList(FBranchList.Objects[lRegionIndex]);

      for lBranchIdx := 0 to lBranchList.Count-1 do
      begin
        lBranchName := lBranchList[lBranchIdx];
        lBranchClass := TBranchClass(lBranchList.Objects[lBranchIdx]);
        //lBranchId := String(lBranchObj['BranchId']);

        //lBranchPos := FBranchListForSelection.Add(lBranchName);//) := TStringList.Create;
        lBranchPos := FBranchListForSelection.AddObject(lBranchName, lBranchClass);//) := TStringList.Create;

        //FBranchListIdsForSelection.Insert(lBranchPos, inttostr(lBranchID) );

      end;
    end;
  end;


  for lBranchIdx := 0 to FBranchListForSelection.Count-1 do
  begin
    lBranchName := FBranchListForSelection[lBranchIdx];
    lBranchClass := TBranchClass(FBranchListForSelection.Objects[lBranchIdx]);//) := TStringList.Create;
    lBranchName := lBranchName + lBranchClass.Id; //String(lBranchObj['BranchId']);
  end;

  PopulateBranchNodes;

end;

procedure TConfigurationForm.InitialiseConfig;
begin
  FBranchListForSelection := TStringList.Create;
  FBranchListForSelection.Sorted := true;
  FBranchListIdsForSelection := TStringList.Create;
end;


procedure TConfigurationForm.PopulateBranchNodes;
var
  lIdx: integer;
  BranchNode: TTreeNode;
  lBranchName: string;
begin
  BranchTV.BeginUpdate;
  BranchTV.Items.Clear;

  for lIdx := 0 to FBranchListForSelection.Count-1 do
  begin
    lBranchName := FBranchListForSelection[lIdx];
    BranchNode := BranchTV.Items.Add(lBranchName);
    BranchNode.Text := lBranchName;
    BranchNode.NodeType := ntCheckBox;
  end;
  BranchTV.EndUpdate;
end;

procedure TConfigurationForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CloseButton := TButton.Create('CloseButton');
  SaveButton := TButton.Create('SaveButton');
  WebPanel1 := TPanel.Create('ConfigurationPanel');
  RegionTV := TTreeView.Create('RegionTV');
  BranchTV := TTreeView.Create('BranchTV');
  BranchListTimer := TTimer.Create(Self);

  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  RegionTV.BeforeLoadDFMValues;
  BranchTV.BeforeLoadDFMValues;
  BranchListTimer.BeforeLoadDFMValues;
  try
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnShow', 'WebFormShow');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 520;
    CloseButton.Top := 432;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 2;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 418;
    SaveButton.Top := 432;
    SaveButton.Width := 96;
    SaveButton.Height := 25;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 2;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 8;
    WebPanel1.Top := 8;
    WebPanel1.Width := 608;
    WebPanel1.Height := 401;
    WebPanel1.ChildOrder := 4;
    WebPanel1.TabOrder := 2;
    RegionTV.SetParentComponent(WebPanel1);
    RegionTV.Name := 'RegionTV';
    RegionTV.Left := 16;
    RegionTV.Top := 16;
    RegionTV.Width := 220;
    RegionTV.Height := 289;
    RegionTV.HeightPercent := 100.000000000000000000;
    RegionTV.WidthPercent := 100.000000000000000000;
    RegionTV.TabOrder := 0;
    RegionTV.TabStop := True;
    SetEvent(RegionTV, Self, 'OnClickCheckBox', 'RegionTVClickCheckBox');
    BranchTV.SetParentComponent(WebPanel1);
    BranchTV.Name := 'BranchTV';
    BranchTV.Left := 242;
    BranchTV.Top := 14;
    BranchTV.Width := 220;
    BranchTV.Height := 289;
    BranchTV.HeightPercent := 100.000000000000000000;
    BranchTV.WidthPercent := 100.000000000000000000;
    BranchTV.TabOrder := 1;
    BranchTV.TabStop := True;
    BranchListTimer.SetParentComponent(Self);
    BranchListTimer.Name := 'BranchListTimer';
    BranchListTimer.Enabled := False;
    BranchListTimer.Interval := 100;
    SetEvent(BranchListTimer, Self, 'OnTimer', 'BranchListTimerTimer');
    BranchListTimer.Left := 296;
    BranchListTimer.Top := 432;
  finally
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    RegionTV.AfterLoadDFMValues;
    BranchTV.AfterLoadDFMValues;
    BranchListTimer.AfterLoadDFMValues;
  end;
end;

end.