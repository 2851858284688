unit BaseDashboardItem;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.ExtCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  SharedDataModule,
  Simple.Dashboard.Return.Types,
  Data.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCChart, Vcl.Forms, Sys.Return.Types,
  XData.Web.Client;


type
  TBaseDashboardItem = class(TPanel)
  private
    { Private declarations }
    FDashboardId: integer;
    FTitleLabel: TLabel;
    FTemplate: TDashboardItemTemplate;
    FDashboardParameters: string;
    FResponse: TDashboardResponse;
    FChartType: string;
    FChartTypeFixed: boolean;
    FSummaryIndex: integer;
    FSummaryFieldCount: integer;

    FDashboardChart: TTMSFNCChart;

    FChart: TTMSFNCChart;
    FChartData: string;
    FSummaryData: string;
    FSummaryGrid: TTableControl;
    FConfiguration: TDashboardItemConfig;
    FSummaryPresent: boolean;
    FSummaryGridPresent: boolean;
    FSummaryType: string;
    FFullSummary: boolean;

    FChartTitle: TTMSFNCChartTitle;

    FOwner: TComponent;
    FTitle: string;
    FYValueNames: string;
    FXLabelNames: string;
    FLegendText: string;
    FDashboardIndex: integer;
    FDrillDownPresent: boolean;
    FDrillDownSQL: string;
    FComboBox: TComboBox;
    ChartTimer: TTimer;

    function JSONToNamesArray(AJSON: string):TTMSFNCChartJSONNamesArray;

    procedure ComboBoxChange(Sender: TObject);
    procedure TMSFNCPieChart1GetSerieLegendText(Sender: TObject;
                ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint;
                var ALegendText: string);
    procedure ChartTimerTimer(Sender: TObject);
    function GetSummaryFieldCount: integer;
    procedure TestClick(Sender: TObject);
  protected
    procedure SetTitle(ATitle: string);
    procedure AddChartAndData(ADataJSON: string);
    procedure CreateChart;
    procedure CreateTitleLabel;

    procedure PopulateSummaryTableRowData(ARowIndex: integer; ARowData: JS.TJSObject);

    procedure CreateSummaryFieldsTableRowIndex(ASummaryData: string; ARowIndex: integer);
    procedure AddLegend(AChart: TTMSFNCChart);
    procedure SetDashboardId(ADashboardItemId: integer);

//    procedure CreateSummaryTable(ASummaryData: string; AFieldNames: TArray<String>);overload;
    procedure CreateSummaryTable(ARowCount: integer; AColumnCount: integer); virtual;//overload;

    procedure PopulateSummaryTableData(ASummaryData: string; AColumnCount: integer; ARowCount: integer);
    procedure RunSummary(AIndex: integer);
    procedure NewSummarySelected;virtual;
    procedure NewSummaryIndividualData(ASummaryParam: string);

    [async]
    procedure RunSummarySQL(ASQL: string); async; virtual;

    procedure CreateComboBox;
    procedure PopulateComboBox; virtual;

    procedure ProcessResponse(AResponse: TDashboardResponse); virtual;
    procedure CreateComponents; virtual;
    procedure DisplayData; virtual;

    function GetChartHeight: integer; virtual; abstract;
    function GetSummaryRowCount: integer; virtual; abstract;
    function GetSummaryColCount: integer; virtual; abstract;

  public
    { Public declarations }
    constructor Create(AOwner: TComponent);override;
    [async]
    function Run: boolean; async; virtual;

    procedure Refresh; virtual;
    procedure AddData(AText: string; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray);//, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray); //ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);

    property DashboardChart: TTMSFNCChart read FDashboardChart;
    property Title: string read FTitle write SetTitle;
    property Template: TDashboardItemTemplate read FTemplate write FTemplate;
    property SummaryGridPresent: boolean read FSummaryGridPresent write FSummaryGridPresent;
    property DashboardId: integer read FDashboardId write SetDashboardId;
    property DashboardParameters: string read FDashboardParameters write FDashboardParameters;
    property SummaryComboBox: TComboBox read FComboBox write FComboBox;
    property Configuration: TDashboardItemConfig read FConfiguration;
    property DrillDownSQL: string read FDrillDownSQL;
    property ChartData: string read FChartData;
    property SummaryData: string read FSummaryData;
    property SummaryGrid: TTableControl read FSummaryGrid write FSummaryGrid;
    property SummaryFieldCount: integer read FSummaryFieldCount write FSummaryFieldCount;
  end;


implementation

{ TMappingsForm }

uses
  System.UITypes;


constructor TBaseDashboardItem.Create(AOwner: TComponent);
begin
  inherited;

  FOwner := AOwner;
  //Color := clWhite;
  Color := clRed;
  BorderStyle := bsNone;
  Height := 600;
  Width := 700;
end;

procedure TBaseDashboardItem.CreateChart;
begin
  if not assigned(FDashboardChart) then
  begin
    if FChartType = 'Bar' then
    begin
      FDashboardChart := TTMSFNCBarChart.Create(FOwner);
    end
    else if FChartType = 'Pie' then
    begin
      FDashboardChart := TTMSFNCPieChart.Create(FOwner);
      FDashboardChart.OnGetSerieLegendText := TMSFNCPieChart1GetSerieLegendText;
    end
    else if FChartType = 'Line' then
    begin
      FDashboardChart := TTMSFNCLineChart.Create(FOwner);
    end;

    DashboardChart.Top := 0;
    DashboardChart.Left := 0;
    DashboardChart.Parent := Self;
    DashboardChart.Align := alTop;
    DashboardChart.Height := GetChartHeight;//300;
    //DashboardChartHeight := 500;
    DashboardChart.Legend.Left := 300;

    DashboardChart.Stroke.Kind := gskNone;
    DashboardChart.Title.Line := False;
    DashboardChart.XAxis.Line := False;
    DashboardChart.YAxis.Line := False;
  end;
end;

procedure TBaseDashboardItem.CreateComponents;
begin
  CreateTitleLabel;
end;


procedure TBaseDashboardItem.CreateTitleLabel;
begin
  if not assigned(FTitleLabel) then
  begin
    FTitleLabel := TLabel.Create(FOwner);
    FTitleLabel.Parent := Self;
    FTitleLabel.Align := alTop;
    FTitleLabel.Height := 30;
    FTitleLabel.Font.Size := 14;
    FTitleLabel.Font.Style := [fsBold];
    FTitleLabel.OnClick := TestClick;
  end;
end;

procedure TBaseDashboardItem.CreateComboBox;
begin
  if not Assigned(FComboBox) then
  begin
    FComboBox := TComboBox.Create(FOwner);
    FComboBox.Parent := Self;
    //KSSFComboBox.Align := alTop;
    FComboBox.Align := alBottom;
    FComboBox.ElementClassName := 'form-select';
    FComboBox.OnChange := ComboBoxChange;
  end;
end;

procedure TBaseDashboardItem.PopulateComboBox;
var
  lData: string;
  lMainDataArray: JS.TJSArray;
  lColumnData: JS.TJSObject;
  lChartData: string;
  lIdx: integer;
begin
  //KSS Check why we are using FChartData
  FComboBox.Clear;
  lChartData := FChartData;
  lMainDataArray := JS.toArray(TJSJSON.parse(lChartData));

  for lIdx := 0 to lMainDataArray.Length-1 do
  begin
    lColumnData := JS.toObject(lMainDataArray.Elements[lIdx]);
    lData := String(lColumnData[FConfiguration.DrillDownFieldName]);
    FComboBox.Items.Add(lData);
  end;

  FComboBox.ItemIndex := 0;
end;

procedure TBaseDashboardItem.AddLegend(AChart: TTMSFNCChart);
var
  lIdx: integer;
begin
  try
    for lIdx := 0 to Length(FConfiguration.LegendData)-1  do
    begin
      AChart.series.Items[lIdx].LegendText := FConfiguration.LegendData[lIdx];
    end;
  except

  end;
end;

procedure TBaseDashboardItem.DisplayData;
begin
  PopulateComboBox;
  CreateSummaryTable(GetSummaryRowCount, GetSummaryColCount);
  RunSummary(0);
end;

procedure TBaseDashboardItem.ProcessResponse(AResponse: TDashboardResponse);
var
  lConfigObj: JS.TJSObject;
  lConfigJSON: string;
begin
  lConfigJSON := AResponse.Configuration;
  lConfigObj := JS.toObject(TJSJSON.parse(lConfigJSON));
  FConfiguration := TDashboardItemConfig(lConfigObj);

  FChartData := FResponse.Value;
  FDrillDownPresent := FResponse.DrillDownPresent;
  FSummaryPresent := FResponse.SummaryPresent;
  FChartType := FResponse.ChartType;
  FChartTypeFixed := FResponse.ChartTypeFixed;
  FSummaryType := FResponse.SummaryType;
  FFullSummary := FResponse.FullSummaryAvailable;

  FSummaryData := FResponse.SummaryData;


  //if FSummaryData <> '' then
  FSummaryFieldCount := GetSummaryFieldCount;

  if FDrillDownPresent then
    FDrillDownSQL := FResponse.DrillDownSQL;
end;

function TBaseDashboardItem.Run: boolean;
var
  lResponse: TDashboardResponse;
  lConfigObj: JS.TJSObject;
  lConfigJSON: string;

  lRetval: TXDataClientResponse;
  lObject: JS.TJSObject;

//const
//  SVC_GET_ITEM = 'ISimpleDashboardService.GetDashboardItem';


begin
  try
    FResponse := Await(SharedData.Dashboard.GetDashboardItem(FDashboardID.ToString, FDashboardParameters, FTemplate));

    lResponse := FResponse;
    ProcessResponse(lResponse);

    CreateComponents;

    CreateTitleLabel;
    Title := FResponse.Title;

    DisplayData;

    result := true;
  except
    result := false;
  end;

  Title := FTitle + 'H:' + inttostr(Height) + ' W:' + inttostr(Width);
end;

procedure TBaseDashboardItem.Refresh;
begin
  Title := FTitle + ' . . . (Refreshing)';
  Run;
end;



procedure TBaseDashboardItem.AddChartAndData(ADataJSON: string);
var
  s, si: TTMSFNCChartSerie;
begin
  if not assigned(DashboardChart) then
  begin
    CreateChart;
  end;

//  AddData(ADataJSON, '', '', JSONToNamesArray(FConfiguration.YValueName),
//        nil, JSONToNamesArray(FConfiguration.XValueLabels));
  AddData(FChartData, '', '', JSONToNamesArray(FConfiguration.YValueName),
        nil, JSONToNamesArray(FConfiguration.XValueLabels));

  DashboardChart.Series[0].XValues.AutoUnits := False;
  AddLegend(DashboardChart);

  DashboardChart.Series[0].Stroke.Kind := gskSolid;

  DashboardChart.Series[0].XGrid.Visible := true;
  DashboardChart.Series[0].YGrid.Visible := true;

  if FChartType = 'Pie' then
  begin
    DashboardChart.Series[0].Legend.Font.Size := 12;
    DashboardChart.Series[0].Legend.Visible := True;
    DashboardChart.Series[0].Legend.Position := lpTopLeft;
  end
  else
  begin
    DashboardChart.Series[0].Legend.Visible := False;
  end;
(*
  if FDrillDownPresent then
  begin
    CreateComboBox;
    PopulateComboBox;
    CreateSummaryTable(Length(FConfiguration.ColumnHeadings), 5);   // KSS This
    //CreateSummaryTable(5, Length(FConfiguration.ColumnHeadings));   // KSS This
    RunSummary(0);
  end
  else if FSummaryPresent then
    CreateSummaryTable(FResponse.SummaryData, FConfiguration.ColumnHeadings)
  else if FFullSummary  then
  begin
    CreateComboBox;
    PopulateComboBox;
    CreateSummaryTable(Length(FConfiguration.FieldsForDisplay), 5);
    PopulateSummaryTableData(FResponse.SummaryData, Length(FConfiguration.FieldsForDisplay), 5);
  end;*)
end;

function TBaseDashboardItem.JSONToNamesArray(AJSON: string):TTMSFNCChartJSONNamesArray;
var
  lValues: JS.TJSArray;
begin
  lValues := JS.toArray(AJSON);
  result := TTMSFNCChartJSONNamesArray(lValues);
end;

procedure TBaseDashboardItem.AddData(AText: string; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray);
begin
  DashboardChart.LoadFromJSONTextDataEx(AText, ASeriesName, APointsName, AYValueNames,
  AXValueNames, AXLabelNames);
end;

procedure TBaseDashboardItem.SetDashboardId(ADashboardItemId: integer);
begin
  // Select the DashboardItem from the table
  FDashboardId := ADashboardItemId;
end;

procedure TBaseDashboardItem.SetTitle(ATitle: string);
begin
  FTitle := ATitle;
  FTitleLabel.Caption := FTitle;
end;

procedure TBaseDashboardItem.ComboBoxChange(Sender: TObject);
begin
  NewSummarySelected;
end;

procedure TBaseDashboardItem.NewSummarySelected;
var
  lDrillDownSQL: string;
  lDrillDownFieldName: string;
  lNewSummaryParam: string;
begin
  Sleep(50);
//  lNewSummaryParam := FComboBox.Items[FComboBox.ItemIndex];
//  lDrillDownFieldName := '@' + FConfiguration.DrillDownFieldName;
//  lDrillDownSQL := StringReplace(FDrillDownSQL, lDrillDownFieldName, lNewSummaryParam, [rfReplaceAll, rfIgnoreCase]);
//  RunSummarySQL(lDrillDownSQL);
//  CreateSummaryFieldsTableRowIndex(FChartData, FComboBox.ItemIndex);
end;

procedure TBaseDashboardItem.NewSummaryIndividualData(ASummaryParam: string);
var
  lData: string;
  lMainDataArray: JS.TJSArray;
  lColumnData: JS.TJSObject;
  lChartData: string;
  lDrillDownField: string;
  lDrillDownSQL: string;
  lDrillDownName: string;
begin
  Sleep(70);
//  lChartData := FChartData;
//  lMainDataArray := JS.toArray(TJSJSON.parse(lChartData));
//  lColumnData := JS.toObject(lMainDataArray.Elements[AIndex]);

end;

procedure TBaseDashboardItem.RunSummary(AIndex: integer);
var
  lData: string;
  lMainDataArray: JS.TJSArray;
  lColumnData: JS.TJSObject;
  lChartData: string;
  lDrillDownField: string;
  lDrillDownSQL: string;
  lDrillDownName: string;
begin
  lChartData := FChartData;
  lMainDataArray := JS.toArray(TJSJSON.parse(lChartData));
  lColumnData := JS.toObject(lMainDataArray.Elements[AIndex]);

  lDrillDownField := FConfiguration.DrillDownFieldName;
  lDrillDownSQL := FDrillDownSQL;
  lDrillDownName :=  String(lColumnData[lDrillDownField]);
  lDrillDownField := '@' + FConfiguration.DrillDownFieldName;
  lDrillDownSQL := StringReplace(FDrillDownSQL, lDrillDownField, lDrillDownName, [rfReplaceAll, rfIgnoreCase]);

  RunSummarySQL(lDrillDownSQL);
end;

procedure TBaseDashboardItem.RunSummarySQL(ASQL: string);
var
  lSummaryData: string;
begin
  lSummaryData := Await(SharedData.Dashboard.GetDashboardSummaryData(ASQL, FConfiguration.SummaryFields));
  PopulateSummaryTableData(lSummaryData, GetSummaryColCount, GetSummaryRowCount);
end;

procedure TBaseDashboardItem.CreateSummaryFieldsTableRowIndex(ASummaryData: string; ARowIndex: integer);
var
  I, J: integer;
  lColumnName: string;
  lFieldType: string;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;

  lCount: integer;
  lMA: TSummaryArray;
begin
  lSummaryData := ASummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    if not Assigned(FSummaryGrid) then
    begin
      FSummaryGrid := TTableControl.Create(Self);
      FSummaryGrid.Parent := Self;
      FSummaryGrid.ColCount := Length(FConfiguration.SummaryFields);
      FSummaryGrid.RowCount := lSummaryArray.Length + 1;
      FSummaryGrid.Height := (FSummaryGrid.RowCount+1) * 28;
      FSummaryGrid.Font.Height := 4;
      //KSSFSummaryGrid.Align := alTop;
      FSummaryGrid.Align := alBottom;
      FSummaryGrid.AlignWithMargins := True;
      FSummaryGrid.Options.ImageAlign := taCenter;
    end;

    for I := 0 to Length(FConfiguration.SummaryFields)-1 do
    begin
      //if I = 0 then
      //  FSummaryGrid.Cells[I,0] := FConfiguration.SummaryFields[I].DisplayName;

      lFieldType :=  FConfiguration.SummaryFields[I].FieldType;
      lColumnName := FConfiguration.SummaryFields[I].ToField;
      lValue := JS.toObject(lSummaryArray[ARowIndex]);

      if Uppercase(lFieldType) = 'CURRDEC' then
        FSummaryGrid.Cells[I,1] := '£' + String(lValue[lColumnName])
      else
        FSummaryGrid.Cells[I,1] := String(lValue[lColumnName]);
    end;
  finally
  end;
end;

procedure TBaseDashboardItem.CreateSummaryTable(ARowCount: integer; AColumnCount: integer);
var
  I,J: integer;
  lColumnName: string;
begin
//  ARowCount := 1;
  try
    if not Assigned(FSummaryGrid) then
    begin
      FSummaryGrid := TTableControl.Create(Self);
      FSummaryGrid.Parent := Self;
      FSummaryGrid.ColCount := GetSummaryColCount;//AColumnCount;
      FSummaryGrid.RowCount := GetSummaryRowCount + 1;  //ARowCount + 1;
      //FSummaryGrid.Height := (ARowCount+1) * 28;
      FSummaryGrid.Height := (GetSummaryRowCount+1) * 28;
      FSummaryGrid.Font.Height := 4;
      //FSummaryGrid.Align := alTop;
      FSummaryGrid.Align := alBottom;
      FSummaryGrid.AlignWithMargins := True;
      FSummaryGrid.Options.ImageAlign := taCenter;
    end;

    for I := 0 to AColumnCount-1 do
    begin
      lColumnName := FConfiguration.SummaryFields[I].ToField;
      FSummaryGrid.Cells[I,0] := FConfiguration.SummaryFields[I].DisplayName;
    end;
  finally
  end;
end;

(*
procedure TBaseDashboardItem.CreateSummaryTable(ASummaryData: string; AFieldNames: TArray<String>);
var
  I, J: integer;
  lColumnName: string;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;
//  lCount: integer;
begin
  lSummaryData := ASummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    if not Assigned(FSummaryGrid) then
    begin
      FSummaryGrid := TWebTableControl.Create(Self);
      FSummaryGrid.Parent := Self;
      FSummaryGrid.ColCount := Length(AFieldNames);
      FSummaryGrid.RowCount := lSummaryArray.Length + 1;
      FSummaryGrid.Height := (FSummaryGrid.RowCount+1) * 28;
      FSummaryGrid.Font.Height := 4;
      FSummaryGrid.Align := alTop;
      FSummaryGrid.AlignWithMargins := True;
      FSummaryGrid.Options.ImageAlign := taCenter;
    end;

    for I := 0 to Length(AFieldNames)-1 do
    begin
      lColumnName := AFieldNames[I];
      FSummaryGrid.Cells[I,0] := StringReplace(lColumnName, '_', ' ', [rfReplaceAll]);

      for J := 0 to lSummaryArray.Length-1 do
      begin
        lValue := JS.toObject(lSummaryArray[J]);
        FSummaryGrid.Cells[I,J+1] := String(lValue[lColumnName]);
      end;
    end;
  finally
  end;
end;
*)
procedure TBaseDashboardItem.PopulateSummaryTableData(ASummaryData: string; AColumnCount: integer; ARowCount: integer);
var
  lRowIndex: integer;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;
begin
  lSummaryData := ASummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    for lRowIndex := 0 to ARowCount-1 do
    begin
      lValue := JS.toObject(lSummaryArray[lRowIndex]);
      PopulateSummaryTableRowData(lRowIndex, lValue);
    end;
  finally
  end;
end;

procedure TBaseDashboardItem.PopulateSummaryTableRowData(ARowIndex: integer; ARowData: JS.TJSObject);
var
  lColumnIndex: integer;
  lColumnName: string;
  lDisplayFormat: string;
begin
  for lColumnIndex := 0 to Length(FConfiguration.SummaryFields)-1 do
  begin
    lColumnName := FConfiguration.SummaryFields[lColumnIndex].ToField;
    FSummaryGrid.Cells[lColumnIndex, ARowIndex+1] := String(ARowData[lColumnName]);
  end;
end;

procedure TBaseDashboardItem.TMSFNCPieChart1GetSerieLegendText(Sender: TObject;
  ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; var ALegendText: string);
begin
//
end;

function TBaseDashboardItem.GetSummaryFieldCount: integer;
begin
  result := Length(FConfiguration.SummaryFields);
end;

procedure TBaseDashboardItem.ChartTimerTimer(Sender: TObject);
begin
//  ChartTimer.Enabled := False;
//  Visible := true;

//  if assigned(FNCChart) then
//    FNCChart.Visible := True;
end;

procedure TBaseDashboardItem.TestClick(Sender: TObject);
begin
  Refresh;
end;

end.


