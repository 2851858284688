unit Simple.Dashboard.Return.Types;

interface

uses System.Generics.Collections {$IFDEF PAS2JS},JS{$ENDIF};

type

{$SCOPEDENUMS ON}

{
When we start using Entities this should change to  TDashboardFormat = (Money,Number,Integer,Text,Image,Graph);
See XData-Core\Models\Dashboard\SMX.Dashboard.Entities
}
TDashboardResponseType = (NoResult, Money, Number, Integer, Text, Image, Graph, HTML);
//TDashboardValueScale = (None, Unitary,

[Enumeration(TEnumMappingType.emString,
    'Dec,Str,Int,Flt,AMPM,Month,CurrDec')]
TDashboardFieldType = (Dec,Str,Int,Flt,AMPM,Month,CurrDec);


TSummaryArray = Class
  SearchName: string;
  ToField: string;
  AggregateFunction: string;
  AggregateStore: string;
  DisplayName: string;
  FieldType: string;

End;


TDashboardItemConfig = class
  private
  public
    //Title: string;
    YValueName: string;
    XValueLabels: string;
    XFieldDisplay: string;
    LegendText: string;
    SummaryTablePresent: boolean;
    LegendData: TArray<String>;
    ColumnHeadings: TArray<String>;
    SummaryRecordDisplay: integer;
    DrillDownFieldName: string;
    DrilDownSQL: string;
    FieldsForSearch: TArray<String>;
    FieldsForDisplay: TArray<String>;
    SummaryFields: TArray<TSummaryArray>;
    {$IFDEF PAS2JS}
    procedure Assign(Source: TJSObject);
    {$ENDIF}
end;

TDashboardResponse = class
  private
    function GetItemType: TDashboardResponseType;
    procedure SetItemType(const Value: TDashboardResponseType);
  public
  Value: String;
  Key: String;
  Description: String;
  Hint: String;
  ItemType: String;
  Scale: String;
  Expires: TDateTime;
  Title: string;
  Configuration: string;
  SummaryType: string;
  SummaryPresent: boolean;
  SummaryData: string;
  DrillDownPresent: boolean;
  DrillDownSQL: string;
  ChartType: string;
  ChartTypeFixed: boolean;
  FullSummaryAvailable: boolean;
//  ColumnHeadings: string;
//  ColumnList: string { TODO : for tables and garpahs: a csv list of column headers, which will also show how many columns there are }
  function AsFormatted(const AFormat: string = '#,##0'): string;
  {$IFDEF PAS2JS}
  procedure Assign(Source: TJSObject);
  {$ENDIF}
  property Item_Type: TDashboardResponseType read GetItemType write SetItemType;
//  property Value_Scale: TDashboardValueScale

end;

TDashboardSummaryDataResponse = class
  private

  protected

  public
    SummaryData: string;
    {$IFDEF PAS2JS}
    procedure Assign(Source: TJSObject);
    {$ENDIF}
end;


const
OUTSTANDING_CLAIMS = 'OUTSTANDING_CLAIMS';
DONATIONS_BY_MONTH = 'DONATIONS_BY_MONTH';
TOTAL_GIFTAID_CLAIMED = 'TOTAL_GIFTAID_CLAIMED';
CLAIMS_BREAKDOWN = 'CLAIMS_BREAKDOWN';
DONOR_COUNT = 'DONOR_COUNT';

implementation

uses System.Rtti, System.SysUtils;


{$SCOPEDENUMS OFF}

{ TDashboardResponse }

{ TDashboardResponse }

function TDashboardResponse.AsFormatted(const AFormat: string): string;
var
  lValue: Integer;
begin
  lValue := StrToIntDef(Value, 0);
  Result := FormatFloat(AFormat, lValue);
end;

{$IFDEF PAS2JS}
procedure TDashboardResponse.Assign(Source: TJSObject);
begin
  Value := String(Source.Properties['Value']);
  Key := String(Source.Properties['Key']);
  Description := String(Source.Properties['Description']);
  Hint := String(Source.Properties['Hint']);
  ItemType := String(Source.Properties['ItemType']);
  Scale := String(Source.Properties['Scale']);
  Expires := TDateTime(Source.Properties['Expires']);
  Title :=  String(Source.Properties['Title']);
  Configuration :=  String(Source.Properties['Configuration']);
  SummaryData :=  String(Source.Properties['SummaryData']);
  SummaryType :=  String(Source.Properties['SummaryType']);
  //ColumnHeadings :=  String(Source.Properties['ColumnHeadings']);
  SummaryPresent := Boolean(Source.Properties['SummaryPresent']);
  DrillDownPresent := Boolean(Source.Properties['DrillDownPresent']);
  DrillDownSQL := String(Source.Properties['DrillDownSQL']);
  ChartType := String(Source.Properties['ChartType']);
  ChartTypeFixed := boolean(Source.Properties['ChartTypeFixed']);
  FullSummaryAvailable := boolean(Source.Properties['FullSummaryAvailable']);
end;
{$ENDIF}

function TDashboardResponse.GetItemType: TDashboardResponseType;
begin
  Result := TRttiEnumerationType.GetValue<TDashboardResponseType>(ItemType);
end;

procedure TDashboardResponse.SetItemType(const Value: TDashboardResponseType);
begin
  ItemType := TRttiEnumerationType.GetName<TDashboardResponseType>(Value);
end;

{$IFDEF PAS2JS}
procedure TDashboardItemConfig.Assign(Source: TJSObject);
begin
  YValueName := string(Source.Properties['YValueName']);
  XValueLabels := string(Source.Properties['XValueLabels']);
  XFieldDisplay := string(Source.Properties['XFieldDisplay']);

  LegendText := string(Source.Properties['LegendText']);
  SummaryTablePresent := boolean(Source.Properties['SummaryTablePresent']);
  LegendData := TArray<String>(Source.Properties['LegendData']);
  ColumnHeadings := TArray<String>(Source.Properties['ColumnHeadings']);
  DrillDownFieldName := string(Source.Properties['DrillDownFieldName']);
  DrilDownSQL := string(Source.Properties['DrilDownSQL']);
  FieldsForSearch := TArray<String>(Source.Properties['FieldsForSearch']);
  FieldsForDisplay :=  TArray<String>(Source.Properties['FieldsForDisplay']);
  SummaryFields := TArray<TSummaryArray>(Source.Properties['SummaryFields']);
end;
{$ENDIF}

{$IFDEF PAS2JS}
procedure TDashboardSummaryDataResponse.Assign(Source: TJSObject);
begin
  SummaryData := string(Source.Properties['SummaryData']);
end;
{$ENDIF}


end.
