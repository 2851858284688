 program CSYDashboard;

uses
  Vcl.Forms,
  WEBLib.Forms,
  SMX.Web.Layout.Utils in '..\..\SMXiLibs\Web-Core-Utils\SMX.Web.Layout.Utils.pas',
  SMX.CRM.Utils in '..\..\SMXiLibs\Web-Core-Utils\SMX.CRM.Utils.pas',
  SMX.Web.Document.Utils in '..\..\SMXiLibs\Web-Core-Utils\SMX.Web.Document.Utils.pas',
  App.Types in '..\XData-Core\Web-Core\App.Types.pas',
  App.Config in '..\XData-Core\Web-Core\App.Config.pas',
  smx.webcore.types in '..\XData-Core\Web-Core\smx.webcore.types.pas',
  WebForm.Core in '..\XData-Core\Web-Core\WebForm.Core.pas'{*.html},
  Dataset.Plugins in '..\XData-Core\Web-Core\Dataset.Plugins.pas',
  Grid.Plugins in '..\XData-Core\Web-Core\Grid.Plugins.pas',
  Grid.Settings in '..\XData-Core\Web-Core\Grid.Settings.pas',
  Paginator.Plugins in '..\XData-Core\Web-Core\Paginator.Plugins.pas',
  MainDataModule in 'MainDataModule.pas',
  BaseForm in 'BaseForm.pas'{*.html},
  BaseList in 'BaseList.pas'{*.html},
  DashboardForm in 'DashboardForm.pas'{*.html},
  ErrorForm in 'ErrorForm.pas'{*.html},
  SMX.Shared in '..\Shared\SMX.Shared.pas',
  JobDetailForm in 'JobDetailForm.pas'{*.html},
  JobBaseForm in 'JobBaseForm.pas'{*.html},
  JobErrorForm in 'JobErrorForm.pas'{*.html},
  SysConfigForm in 'SysConfigForm.pas'{*.html},
  SMX.Web.Utils in '..\..\SMXiLibs\Web-Core-Utils\SMX.Web.Utils.pas',
  InputQueryModule in 'InputQueryModule.pas'{*.html},
  SMX.Web.Service.Consts in '..\XData-Core\Web-Core\SMX.Web.Service.Consts.pas',
  SharedDataModule in 'SharedDataModule.pas',
  MainForm in 'MainForm.pas'{*.html},
  Auth.Service in 'Auth.Service.pas',
  JobDetailBaseForm in 'JobDetailBaseForm.pas'{*.html},
  Job.DetailForm.Manager in 'Job.DetailForm.Manager.pas',
  SimpleDashboard in 'SimpleDashboard.pas',
  Simple.Dashboard.Return.Types in '..\Shared\Simple.Dashboard.Return.Types.pas',
  sys.Return.Types in '..\Shared\sys.Return.Types.pas',
  SMX.Auth.Shared in '..\XData-Core\Auth-Shared\SMX.Auth.Shared.pas',
  UserManager in 'Admin\UserManager.pas'{*.html},
  SMX.Web.Table.Simple in '..\..\SMXiLibs\Web-Core-Utils\SMX.Web.Table.Simple.pas',
  UserEdit in 'Admin\UserEdit.pas'{*.html},
  UserManager.Utils in 'Admin\UserManager.Utils.pas',
  SMX.Web.FileSupport in '..\..\SMXiLibs\Web-Core-Utils\SMX.Web.FileSupport.pas',
  SMX.ReturnTypes in '..\XData-Core\Services\SMX.ReturnTypes.pas',
  SMX.Jobs.Shared in '..\XData-Core\Jobs\SMX.Jobs.Shared.pas',
  DashboardForm2 in 'DashboardForm2.pas'{*.html},
  App.Helper in 'App.Helper.pas',
  CSY.Enumerations in '..\Shared\Entities\CSY.Enumerations.pas',
  BranchGroupManager in 'Admin\BranchGroupManager.pas'{*.html},
  SMX.XData.Utils in '..\XData-Core\Web-Core\SMX.XData.Utils.pas',
  BaseDashboardItem in 'BaseDashboardItem.pas',
  DashboardItemSummaryTop5 in 'DashboardItemSummaryTop5.pas',
  DashboardItemTableOnly in 'DashboardItemTableOnly.pas',
  DashboardItemSingleSummary in 'DashboardItemSingleSummary.pas',
  DashboardItemSummaryIncluded in 'DashboardItemSummaryIncluded.pas',
  Configuration in 'Configuration.pas'{*.html},
  UserDashboardConfiguration in 'UserDashboardConfiguration.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TMainData, MainData);
  Application.Run;
end.
