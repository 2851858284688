Unit Sys.Return.Types;

Interface

Uses
{$IFDEF PAS2JS}
  JS,
{$ELSE}
  SMX.Auth.Server,
{$ENDIF}
  CSY.Enumerations,
  SMX.Auth.Shared;

Type

  TAppUser = Class
    Id: String;
    PhoneNumber: String;
    Title: String;
    GivenName: String;
    LastName: String;
    Email: String;
    UserScope: String;
    JobPosition: String;
    TenantId: String;
    InternalId: Integer;
    UserStatus: String;
    OrganisationId: Integer;
    BranchIdType: String;
    BranchId: Double;
    JobTitle: String;
    UserName: String;
    EmailConfirmed: Boolean;
    UserPassword: string;
  Private
    Function GetIsAdmin: Boolean;
    Function GetBranchIdType: TBranchIdType;
    Function GetUserScope: TUserScope;
    Procedure SetBranchIdType(Const Value: TBranchIdType);
    Procedure SetUserScope(Const Value: TUserScope);
    Function GetUserStatus: TUserStatus;
    Procedure SetUserStatus(Const Value: TUserStatus);
{$IFDEF PAS2JS}
    Function GetFullName: String;
    Function GetInitials: String;
{$ENDIF}
  Public
{$IFDEF PAS2JS}
    Procedure Assign(Value: JS.TJSObject);
{$ENDIF}
    Property BranchId_Type: TBranchIdType Read GetBranchIdType Write SetBranchIdType;
    Property User_Status: TUserStatus Read GetUserStatus Write SetUserStatus;
    Property User_Scope: TUserScope Read GetUserScope Write SetUserScope;

    Property IsAdmin: Boolean Read GetIsAdmin;
{$IFDEF PAS2JS}
    Property FullName: String Read GetFullName;
    Property Initials: String Read GetInitials;
{$ENDIF}
  End;

  TUserCounts = Class
    Pending: Integer;
    Blocked: Integer;
    Active: Integer;
{$IFDEF PAS2JS }
    Procedure Assign(Source: JS.TJSObject);
{$ENDIF }
  End;

  TBranchGroup = Class
  Private
    Function GetBranchStatus: TBranchGroupStatus;
    Procedure SetBranchStatus(Const Value: TBranchGroupStatus);
  Public
    Id: Double;
    Name: String;
    Status: String;
{$IFDEF PAS2JS}
    Constructor Create(Value: JS.TJSObject);
    Procedure Assign(Value: JS.TJSObject);
{$ENDIF}
    Property BranchStatus: TBranchGroupStatus Read GetBranchStatus Write SetBranchStatus;
  End;

  TBranch = Class
  Public
    Id: Double;
    ParentId: Double;
    Name: String;
    Branch: String;
{$IFDEF PAS2JS}
    Constructor Create(Value: JS.TJSObject);
    Procedure Assign(Value: JS.TJSObject);
{$ENDIF}
  End;

  TDashboardItemTemplate = Class
  Public
    Id: integer;
    DashboardItemType: string;
    Title: String;
    Configuration: String;
    SQLSummary: string;
    SQLChart: string;
    ChartType: string;
    ChartTypeFixed: boolean;
    Preferences: string;
{$IFDEF PAS2JS}
    Constructor Create(Value: JS.TJSObject);
    Procedure Assign(Value: JS.TJSObject);
{$ENDIF}
  End;

  TDashboardItemTitle = Class
  Public
    Id: integer;
    Title: String;
    Selected: boolean;
    OrderIndex: integer;
{$IFDEF PAS2JS}
    Constructor Create(Value: JS.TJSObject);
    Procedure Assign(Value: JS.TJSObject);
{$ENDIF}
  End;


Const
  CLAIM_BRANCH_ID_TYPE = 'BranchGroupIdType';
  CLAIM_BRANCH_ID = 'BranchGroupId';

Implementation

Uses
  System.Rtti,
  System.SysUtils;

{ TUserCounts }

{$IFDEF PAS2JS}

Procedure TUserCounts.Assign(Source: JS.TJSObject);
Begin
  Pending := Integer(Source['Pending']);
  Blocked := Integer(Source['Blocked']);
  Active := Integer(Source['Active']);
End;
{$ENDIF}
{ TAppUser }

Function TAppUser.GetBranchIdType: TBranchIdType;
Begin
  Result := TRttiEnumerationType.GetValue<TBranchIdType>(BranchIdType);
End;

Function TAppUser.GetIsAdmin: Boolean;
Begin
  Result := User_Scope In [TUserScope.AdminUser, TUserScope.Administrator, TUserScope.SuperUser];
End;

Function TAppUser.GetUserScope: TUserScope;
Begin
  Result := TRttiEnumerationType.GetValue<TUserScope>(UserScope);
End;

Function TAppUser.GetUserStatus: TUserStatus;
Begin
  Result := TRttiEnumerationType.GetValue<TUserStatus>(UserStatus);
End;

Procedure TAppUser.SetBranchIdType(Const Value: TBranchIdType);
Begin
  BranchIdType := TRttiEnumerationType.GetName<TBranchIdType>(Value);
End;

Procedure TAppUser.SetUserScope(Const Value: TUserScope);
Begin
  UserScope := TRttiEnumerationType.GetName<TUserScope>(Value);
End;

Procedure TAppUser.SetUserStatus(Const Value: TUserStatus);
Begin
  UserStatus := TRttiEnumerationType.GetName<TUserStatus>(Value);
End;

{$IFDEF PAS2JS}

Procedure TAppUser.Assign(Value: JS.TJSObject);
Begin
  PhoneNumber := String(Value['PhoneNumber']);
  BranchId := Double(Value['BranchId']);
  LastName := String(Value['LastName']);
  Email := String(Value['Email']);
  EmailConfirmed := Boolean(Value['EmailConfirmed']);
  UserScope := String(Value['UserScope']);
  JobPosition := String(Value['JobPosition']);
  TenantId := String(Value['TenantId']);
  InternalId := Integer(Value['InternalId']);
  UserStatus := String(Value['UserStatus']);
  OrganisationId := Integer(Value['OrganisationId']);
  Title := String(Value['Title']);
  Id := String(Value['Id']);
  BranchIdType := String(Value['BranchIdType']);
  JobTitle := String(Value['JobTitle']);
  GivenName := String(Value['GivenName']);
  UserName := String(Value['UserName']);
End;

Function TAppUser.GetFullName: String;
Begin
  Result := GivenName + ' ' + LastName;
End;

Function TAppUser.GetInitials: String;
Begin
  If GivenName <> '' Then
    Result := GivenName.Substring(0, 1)
  Else
    Result := '';

  If LastName <> '' Then
    Result := Result + LastName.Substring(0, 1);
End;

{$ENDIF}
{ TBranchGroup }

{$IFDEF PAS2JS}

Procedure TBranchGroup.Assign(Value: JS.TJSObject);
Begin
  Id := Double(Value['Id']);
  Name := String(Value['Name']);
  Status := String(Value['Status']);
End;

Constructor TBranchGroup.Create(Value: JS.TJSObject);
Begin
  Assign(Value);
End;

{$ENDIF}
{ TBranchGroup }

Function TBranchGroup.GetBranchStatus: TBranchGroupStatus;
Begin
  Result := TRttiEnumerationType.GetValue<TBranchGroupStatus>(Status);
End;

Procedure TBranchGroup.SetBranchStatus(Const Value: TBranchGroupStatus);
Begin
  Status := TRttiEnumerationType.GetName<TBranchGroupStatus>(Value);
End;

{ TWebBranch }

{$IFDEF PAS2JS}

Constructor TBranch.Create(Value: JS.TJSObject);
Begin
  Assign(Value);
End;

Procedure TBranch.Assign(Value: JS.TJSObject);
Begin
  Id := Double(Value['Id']);
  ParentId := Double(Value['ParentId']);
  Name := String(Value['Name']);
  Branch := String(Value['Branch']);
End;

{$ENDIF}

{$IFDEF PAS2JS}

Constructor TDashboardItemTemplate.Create(Value: JS.TJSObject);
begin
  Assign(Value);
end;

Procedure TDashboardItemTemplate.Assign(Value: JS.TJSObject);
begin
    Id := integer(Value['Id']);
    DashboardItemType := string(Value['DashboardItemType']);
    Title := String(Value['Title']);
    Configuration := String(Value['Configuration']);
    SQLSummary := string(Value['SQLSummary']);
    SQLChart := string(Value['SQLChart']);
    ChartType := string(Value['ChartType']);
    ChartTypeFixed := boolean(Value['ChartTypeFixed']);
    Preferences := string(Value['Preferences']);
end;
{$ENDIF}



{$IFDEF PAS2JS}

Constructor TDashboardItemTitle.Create(Value: JS.TJSObject);
begin
  Assign(Value);
end;

Procedure TDashboardItemTitle.Assign(Value: JS.TJSObject);
begin
  Id := integer(Value['Id']);
  Title := string(Value['Title']);
  Selected := boolean(Value['Selected']);
  OrderIndex := integer(Value['OrderIndex']);

end;
{$ENDIF}


End.
